/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import '~/styles/global.scss';

// Add fonts installed via NPM
// TODO: figure out how to use non-npm-typeface fonts
// require('typeface-poppins');
// require('typeface-anonymous-pro');
